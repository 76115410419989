<template>
  <v-app>
    <dashboard-core-app-bar
      v-model="expandOnHover"
    />
    <!--
    <dashboard-core-app-bar
      v-if="!mobileDevice"
      v-model="expandOnHover"
    />
    <dashboard-core-app-bar-mobile
      v-if="mobileDevice"
      v-model="expandOnHover"
    />
    -->

    <dashboard-core-drawer
      v-if="isTablet"
      :expand-on-hover.sync="expandOnHover"
    />

    <dashboard-core-view />

    <!-- <dashboard-core-settings v-model="expandOnHover" /> -->
  </v-app>
</template>

<script>
  export default {
    name: 'DashboardIndex',

    components: {
      DashboardCoreAppBar: () => import('./components/core/AppBar'),
      // DashboardCoreAppBarMobile: () => import('./components/core/AppBarMobile'),
      DashboardCoreDrawer: () => import('./components/core/Drawer'),
      // DashboardCoreSettings: () => import('./components/core/Settings'),
      DashboardCoreView: () => import('./components/core/View'),
    },

    data: () => ({
      expandOnHover: false,
    }),
    computed: {
      mobileDevice () {
        return this.$vuetify.breakpoint.xsOnly
      },
      isTablet () {
        return this.$vuetify.breakpoint.smAndDown
      },
    }
  }
</script>
